import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Tabs, Row, Col, message } from "antd";
import { useMutation } from "@apollo/react-hooks";

import SEO from "@components/common/seo";
import LoginForm from "@components/common/loginForm";
// import RegisterForm from "@components/common/registerForm";

import { handleLogin, isLoggedIn } from "@services/auth";
import { mutation } from "@helpers/queryStrings";
import withLocation from "@helpers/hoc/withLocation";
import { Container } from "@styles/login.style";

const { TabPane } = Tabs;

const Login = ({ search }) => {
  const [login, loginData] = useMutation(mutation.login);
  // const [register, registerData] = useMutation(mutation.register);
  const [tab] = useState(search.tabs ? search.tabs : "1");

  useEffect(() => {
    if (!loginData.loading && loginData.data) {
      handleLogin(loginData.data);
      navigate(`/`);
    }

    if (loginData.error && (search.tabs === "1" || !search.tabs)) {
      //Auth.form.error.invalid
      const errMsg =
        loginData.error.networkError.result.errors[0].extensions.data[0]
          .messages[0].message;
      message.error(errMsg);
    }
  }, [loginData, search.tabs]);

  // useEffect(() => {
  //   if (!registerData.loading && registerData.data) {
  //     handleLogin(registerData.data);
  //     navigate(`/`);
  //   }

  //   if (registerData.error && search.tabs === "2") {
  //     //Auth.form.error.email.taken
  //     const errMsg =
  //       registerData.error.networkError.result.errors[0].extensions.data[0]
  //         .messages[0].message;
  //     message.error(errMsg);
  //   }
  // }, [registerData, search.tabs]);

  useEffect(() => {
    if (isLoggedIn()) {
      navigate(`/`);
    }
  }, []);

  const submitLogin = (values) => {
    login({
      variables: {
        input: {
          identifier: values.email,
          password: values.password,
        },
      },
    });
  };

  // const submitRegister = (values) => {
  //   register({
  //     variables: {
  //       input: {
  //         username: values.username,
  //         email: values.email,
  //         password: values.password,
  //         country: values.country,
  //         gender: values.gender,
  //         first_name: values.first_name,
  //         last_name: values.last_name,
  //       },
  //     },
  //   });
  // };

  return (
    <>
      <SEO title="Login" />
      <Container>
        <div>
          <Row
            className="row"
            type="flex"
            justify="space-around"
            align="middle"
          >
            {/* <Col className="col" xs={0} md={12}>
              <div className="login-text-wrapper">
                <h1>Welcome to the Biggest Social Network in the World</h1>
                <p>
                  We are the best and biggest social network with 5 billion
                  active users all around the world. Share you thoughts, write
                  blog posts, show your favourite music via Stopify, earn badges
                  and much more!
                </p>
              </div>
            </Col> */}
            <Col className="col" span={24} md={12}>
              <Tabs
                className="tabs"
                defaultActiveKey={tab}
                tabPosition={"top"}
                onChange={(key) => navigate(`login?tabs=${key}`)}
              >
                <TabPane className="tanpane" tab={`Login`} key={1}>
                  <LoginForm submitLogin={(values) => submitLogin(values)} />
                </TabPane>
                {/* <TabPane className="tanpane" tab={`Register`} key={2}>
                  <RegisterForm
                    submitRegister={(values) => submitRegister(values)}
                  />
                </TabPane> */}
              </Tabs>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default withLocation(Login);
