import React from "react";
import { Form, Input, Button, Checkbox } from "antd";
import styled from "styled-components";

const LoginForm = ({submitLogin}) => {
  const onFinish = values => {
    submitLogin(values)
  };

  return (
    <LoginWrapper>
      <Form
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="login-form"
      >
        <Form.Item
          hasFeedback
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input your email!"
            }
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          hasFeedback
          name="password"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox className="login-remember-me">Remember me</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </LoginWrapper>
  );
};

export default LoginForm;

const LoginWrapper = styled.div`
  .login-form {
    max-width: auto;
  }
  .login-form-forgot {
    float: right;
  }
  .login-remember-me,
  .login-register-now {
    float: left;
  }
  .login-form-button {
    width: 100%;
  }
`;
