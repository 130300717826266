// import React from "react";
import styled from "styled-components";

import { color } from "@helpers/const";
// import landinguser from "@images/commons/landing-users.png";
import logo from "@images/logos/logo.png";

export const Container = styled.div`
  /* height: 100vh; */
  background-image: url(${logo});
  background-position: 0 0;
  background-repeat: repeat;
  -webkit-animation: sidedownscroll 30s linear infinite;
  animation: sidedownscroll 30s linear infinite;
  background-size: auto;
  background-color: red;

  /* & :before {
    background-color: rgba(255, 94, 58, 0.95);
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    z-index: auto;
  } */

  .ant-tabs-top {
    border-radius: 10px;
  }

  & > :before {
    background-color: ${color.primaryTransparentColor};
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    z-index: auto;
  }

  @-webkit-keyframes sidedownscroll {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: -2000px 2000px;
    }
  }

  @keyframes sidedownscroll {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: -2000px 2000px;
    }
  }

  .row {
    height: 100vh;
  }

  .col {
    text-align: center;
    padding: 40px;
  }
  .tabs {
    width: 100%;
    height: auto;
    background-color: ${color.containerBackground};
    margin: 0 auto;
  }

  .tanpane {
    padding: 45px;
  }

  .login-text-wrapper {
    max-width: 450px;
    min-width: 300px;
    margin: 0 auto;
  }

  h1,
  p {
    text-align: left;
  }
`;
